import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import getVideoId from 'get-video-id'
// import YoutubePlayer from 'react-player/youtube'
// import VimeoPlayer from 'react-player/vimeo'
import ReactPlayer from 'react-player'

const Video = ({ value = {} }) => {
  const { url, blockCaption, isBackground } = value
  const videoId = getVideoId(url).id
  let src
  let config = {}
  // let ReactPlayer
  if (url.match(/youtube\.com/)) {
    src = `https://www.youtube.com/embed/${videoId}`
    // ReactPlayer = YoutubePlayer
  } else if (url.match(/vimeo\.com/)) {
    src = `https://player.vimeo.com/video/${videoId}`
    config.vimeo = {
      playerOptions: {
        background: isBackground,
        color: 'CFC3B3'
      }
    }
    // ReactPlayer = VimeoPlayer
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          borderRadius: 'default',
          overflow: 'hidden',
          position: 'relative',
          paddingBottom: '56.25%',
          height: 0,
          iframe: {
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          },
        }}
      >
      <ReactPlayer
        url={src}
        volume={isBackground ? 0 : 1}
        playing={false}
        controls={true}
        config={config}
      />
      </Box>
      {
        blockCaption && (
          <Box mt={'2rem'} sx={{ 'span, div, p': { display: 'inline' } }} textStyle={{ base: 'rism', lg: 'rimd' }}><Text as={'span'} variant={'case'}>↑ </Text>
            <PortableText
              value={blockCaption}
              components={{ block: { normal: ({children}) => <Text textStyle={{ base: 'rism', lg: 'rimd' }}>{children}</Text> }}}
            />
          </Box>
        )
      }
    </React.Fragment>
  )
}

export default Video
