import create from 'zustand'

//
// === Initial State ===
//

export const getInitialState = () => ({

  isMenuOpen: false,
  isMenu: ''

});

export const toggleMenu = (set, get) => () => {
  const {isMenuOpen} = get()

  set({
    isMenuOpen: !isMenuOpen
  })
}

export const openMenu = (event, value, set, get) => { // (event, value)
  const { isMenu } = get()
  event && event.preventDefault()
  console.log('openMenu: ', value)
  if (isMenu?.length > 0) {
    set({ isMenu: '' })
  } else {
    set({ isMenu: value })
  }
}

export const closeMenu = (set) => () => {
  console.log('closeMenu... ')
  set({ isMenu: '' })
}

//
// === useStore interface ===
//

export default create((...args) => ({

  // State
  ...getInitialState(...args),

  // Actions
  toggleMenu: toggleMenu(...args),

  openMenu: (event, value) => openMenu(event, value, ...args),

  closeMenu: closeMenu(...args)
}));
