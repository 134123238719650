import { Box, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import Video from 'components/Modules/Video'
import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import kenn from 'images/sk_kenn.svg'
import monogram from 'images/sk_monogram.svg'
import stephen from 'images/sk_stephen.svg'
import React from 'react'
import useStore from 'stores/useStore'
import { getGatsbyImageData } from 'utils/getGatsbyImageProps'

const Stephen = ({}) => <Box
  position={'absolute'}
  top={'4rem'}
  right={'135px'}
  display={{ base: 'none', md: 'block' }}
>
  <img width='100%' height='auto' src={stephen} />
</Box>

const Kenn = ({}) => <Box
  position={'absolute'}
  bottom={'5rem'}
  right={'4rem'}
  display={{ base: 'none', md: 'block' }}
>
  <img width='auto' height='100%' src={kenn} />
</Box>

const Monogram = ({ x, y }) =>
  <Box
    width={'10rem'}
    height={'10rem'}
    translate={'transform(-50%, -50%)'}
    position={'absolute'}
    top={y ? `${y}%` : '45%'}
    left={x ? `${x}%` : '45%'}
  >
    <img width='100%' height='auto' src={monogram} />
  </Box>

const HeroHome = ({ value }) => {
  const { menu } = useStaticQuery(graphql`
    {
      menu: sanityMenus(title: {eq: "Main"}) {
        submenu: items {
          _key
          _type
          title
          pageRef {
            ... on SanityPageShop {
              slug {
                current
              }
            }
            ... on SanityPageInfo {
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)

  const openMenu = useStore(store => store.openMenu)

  const content = value ? value.content[0] : false
  return <React.Fragment>
    
    <Grid
      as={motion.header}
      position={'relative'} // LinkBox
      initial={'rest'}
      whileHover={'hover'}
      animate={'rest'}
      bg={value.colorTheme?.background?.hex || 'white'}
      alignItems={'end'}
      templateColumns={['repeat(8, 1fr)', null]}
      templateRows={{ base: 'repeat(6, 12vh)' }}
      rowGap={0}
      gap={'2rem'}
      p={'5rem'}
      h={'100vh'}
      zIndex={'banner'}
    >
      {
        content?._type === 'module.image' && content.image &&
          <GridItem
            gridColumn={{ base: '5/9', lg: '5/8', xl: '6/9' }}
            gridRow={{ base: '2/3', lg: '2/6', xl: '2/7' }}
          >
            <GatsbyImage image={getGatsbyImageData(content.image, { square: true, crop: content.image.crop, hotspot: content.image.hotspot, placeholder: 'dominantColor' })} alt={content.image.altText || ''} />
          </GridItem>
      }
      {
        content?._type === 'module.video' && content.url &&
        <GridItem
          gridColumn={{ base: '5/9', lg: '4/9', xl: '/8' }}
          gridRow={{ base: '1/4', lg: '2/5' }}
        >
          <Video value={content} />
        </GridItem>
      }
      <GridItem
        gridColumn={{ base: '1/8', lg: '1/4', xl: '1/3' }}
        gridRow={{ base: '4/7', lg: '4/7' }}
      >
        <Heading as='h1' size='lg' variant='caps'>{'Stay tuned for our relaunch!'}</Heading>
        <Text size='md' mt={'2rem'}>{'Our new website is coming soon. You can reach us for inquiries or orders by messaging us at '}<a href="mailto:contact@stephenkenn.com">contact@stephenkenn.com</a> {' or following us '} <a href="https://instagram.com/stephenkenn">{'@stephenkenn'}</a> </Text>
      </GridItem>
      <Monogram x={35} y={40} />
      <Stephen />
      <Kenn />
    </Grid>
  </React.Fragment>
}

export default HeroHome
