import { HeroHome } from 'components/Hero'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import '../css/font.css'
import '../css/layout.css'


export const ConfigContext = React.createContext({})

export const fragment = graphql`
fragment ImageWithHotspot on SanityImage {
  crop {
    bottom
    left
    top
    right
  }
  hotspot {
    y
    x
    height
    width
  }
  asset {
    id
    url
    altText
    metadata {
      dimensions {
        height
        width
        aspectRatio
      }
      palette {
        dominant {
          background
        }
      }
    }
  }
}
`

const TRANSITION_DURATION = 400;
const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

const Layout = ({ children, location, pageContext }) => {

  const { site } = pageContext
  const { home, allSanityAttribute, response } = useStaticQuery(graphql`
    query {
      home: sanityHome {
        _rawHero(resolveReferences: {maxDepth: 10})
      }
      allSanityAttribute {
        nodes {
          filteringAndSearch {
            prefix
            defaultText
          }
          active
          title
          namedTag
          type
          groups {
            active
            title
            pageRef {
              ... on SanityPageShop {
                slug {
                  current
                }
              }
              ... on SanityPageInfo {
                slug {
                  current
                }
              }
            }
            options {
              _id
              key
              active
              title
              displayTitle
              description
              imageFormats {
                squareImage {
                  ...ImageWithHotspot
                }
                image {
                  ...ImageWithHotspot
                }
              }
            }
          }
          options {
            _id
            key
            active
            title
            displayTitle
            description
            color {
              hex
            }
            size {
              label
              width
              height
              depth
            }
            imageFormats {
              squareImage {
                ...ImageWithHotspot
              }
              image {
                ...ImageWithHotspot
              }
            }
          }
        }
      }
    }
  `)

  const settings = {
    attributes: allSanityAttribute.nodes,
    getAttribute: key => allSanityAttribute.nodes
        .find(({namedTag}) => namedTag === key),
    getOption: (key, value) => {
      // trim key/value so leading/trailing spaces don't f it up
      const attribute = settings.getAttribute(key.trim())
      if (!attribute){
        return
      }
      const options = attribute.options || []
      if (attribute.groups){
        options.push(attribute.groups.map(g => g.options))
      }
      return options.flat(9).find(option => option.title.trim() === value)
    },
    getAllOptions: () => {
      const all_options = settings.attributes.map(attribute => {
        const options = attribute.options || []
        if (attribute.groups){
          options.push(attribute.groups.map(g => g.options))
        }
        return options.flat(9)
      })
      return all_options.flat(9)
    }
  };

  return <React.Fragment>
    {
      // @TODO: Animate Presence here for smoothly displaying Hero on navigating home
      location.pathname === '/' && home._rawHero && <HeroHome value={home._rawHero || {}} />
    }
    {/*<Header location={location} />*/}
    
    {/*<Footer {...site} location={location} />*/}
    {/*<Guides />*/}
  </React.Fragment>
}

Layout.propTypes = {}

Layout.defaultProps = {}

export default Layout
