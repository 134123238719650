import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'utils/getGatsbyImageProps'
import { Container, Heading, Text, Box, Grid, GridItem } from '@chakra-ui/react'

const HeroPage = ({ value }) => {
  const content = value ? value.content[0] : false
  return <Grid as='header'
    bg={value.colorTheme?.background?.hex || 'white'}
    alignItems={'end'}
    templateColumns={['repeat(8, 1fr)', null]}
    templateRows={{ base: 'repeat(6, 12vh)', xl: 'repeat(6, 12vh)' }}
    rowGap={0}
    gap={'2rem'}
    p={'5rem'}
    position={'relative'}
  >
    {
      content?._type === 'image' && content.asset &&
        <GridItem gridColumn={{ base: '3/9', xl: '4/8' }} gridRow={'4/7'}>
          <GatsbyImage image={getGatsbyImageData(content, { hotspot: content.hotspot, placeholder: 'dominantColor' })} alt={content.altText || `${value.title} — ${value.subtitle}`} />
        </GridItem>
    }
    <Box as='hgroup' position='absolute' top={{ base: 8 }} left={{ base: 8, xl: 'calc(14vw)'}}>
      <Container maxW='1200px'>
        <Heading as='h1' size='2xl' variant='caps' lineHeight='shorter'><Text as={'span'} variant={'case'}>→ </Text>{value.title}</Heading>
        <Heading as='p' size='xl' lineHeight='short'>{value.subtitle}</Heading>
      </Container>
    </Box>
  </Grid>
}

export default HeroPage
