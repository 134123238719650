// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react'


const defaultColors = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  gray: {
    0: '#DDDEDD',
    1: '#EEEEEF',
    2: '#DDDEE1',
    3: '#C4C6CB'
  },
  canvas: {
    0: '#FCFAF5',
    1: '#F2EAE0',
    2: '#CFC3B3'
  },
  overlay: 'rgba(196, 198, 203, 0.8)'
}

const colors = {
  text: defaultColors.black,
  background: defaultColors.white,
  secondary: defaultColors.gray[0],
  selection: defaultColors.gray[0],
  link: defaultColors.black,
  ...defaultColors
}

const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '1280px',
  xl: '1680px',
  '2xl': '2560px',
}

// Our sizing is based on 10px, so we have modified chakra's scale so that our 10px will equal roughly what chakra equals when using 16px. Internally chakra uses these spacings for sizing various things like drawers etc.
// But in our frontend, perhaps we prefer to use rems directly, as 10px rems are easy to calculate

// commented below is the spacings we most commonly use in the design
const spacing = {
  space: {
    px: '1px',
    0.5: '0.2rem',
    1: '0.4rem',
    1.5: '0.5rem', // 5px (1.5)
    2: '0.8rem',
    2.5: '1rem', // 10px (2.5)
    3: '1.2rem',
    3.5: '1.4rem',
    4: '1.5rem', // 15px (4)
    5: '2rem', // 20px (5)
    6: '2.5rem', // 25px (6)
    7: '3rem', // 30px (7)
    8: '3.5rem',
    9: '4rem', // 40px (9)
    10: '4.5rem',
    12: '5rem', // 50px (12)
    14: '5.5rem',
    16: '6rem',
    20: '7rem',
    24: '8rem', // 80px (24)
    28: '10rem',
    32: '12rem',
    36: '14rem',
    40: '16rem',
    44: '18rem',
    48: '20rem',
    52: '22rem',
    56: '24rem',
    60: '26rem',
    64: '28rem',
    72: '30rem',
    80: '34rem',
    96: '38rem',
  }
}

// 1rem = 10px
/*
const spacing = {
  px: '1px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem', // 10px
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem', // 112px
  32: '8rem',
  36: '9rem',
  40: '10rem', // 160px
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
}
*/

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800
}

const styles = {
  global: {
    html: {
      fontFamily: 'body',
      bg: 'white',
      color: 'text',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textRendering: 'optimizeLegibility',
      fontSize: '10px',
      lineHeight: '1.5',
      margin: 0,
      padding: 0,
      minHeight: '100%',
      position: 'relative',
      overflowX: 'hidden',
      '& ::selection': {
        bg: 'selection',
        color: 'text',
      },
    },
    body: {
      bg: 'white',
      color: 'black',
      fontSize: '1.8rem'
    },
    a: {
      color: 'link',
      textDecoration: 'none',
      cursor: 'pointer',
      alignItems: 'center',
      fontSize: 'inherit',
      WebkitTouchCallout: 'none',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      ':hover': {
        textDecoration: 'none',
      },
      ':visited': {
        color: 'link'
      }
    },
    '.css-grid': {
      display: 'grid',
      px: '5rem',
      gridTemplateColumns: ['repeat(4, 1fr)', null],
      gap: '5rem'
    },
    p: {
      my: 0
    },
    // tables
    table: {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      width: '100%',
      borderBottom: '1px solid black',
      'thead th td': {
        height: '2.5rem',
      },
      'tbody tr td': {
        height: '3.125rem',
        verticalAlign: 'initial'
      },
      'tr td': {
        color: 'black',
        fontSize: '18px',
        // height: '2.5rem',
        border: '1px solid black',
        // borderLeft: 'none',
        // borderBottom: 'none',
        p: 0,
      },
      tr: {
        'td:first-of-type': {
          borderLeft: 'none'
        },
        ':last-of-type': {
          'td': {
            borderBottom: 'none'
          }
        }
      },
      td: {
        padding: '0 .625rem',
        textAlign: 'left',
      },
      th: {
        bg: 'white',
        whiteSpace: 'nowrap',
        variant: 'text.caps',
        verticalAlign: 'initial',
        // borderTop: '1px solid',
        // borderTopColor: 'black',
        borderBottom: '1px solid',
        borderBottomColor: 'black',
        fontWeight: 'body',
        color: 'black',
        fontSize: '18px',
        textAlign: 'left',
        p: '1.5rem',
        /*':first-of-type': {
          borderLeft: '1px solid',
          borderLeftColor: 'gray.2',
          borderRadius: '4px 0 0 4px',
        },
        ':last-of-type': {
          borderRight: '1px solid',
          borderRightColor: 'gray.2',
          borderRadius: '0 4px 4px 0',
        },*/
      },
    },
  }
}

const fonts = {
  body: '"RiformaLLWeb-Regular", Arial, sans-serif',
  heading: 'inherit'
}

const fontSizes = {
  // 'xs': TBD
  'sm': '18px',
  'md': '26px',
  'lg': '34px',
  'xl': '48px',
  '2xl': '112px'
}

const fontWeights = {
  normal: '400'
}

const lineHeights = {
  normal: 'normal',
  none: 1,
  shorter: '1.04', // xxl
  short: 1.23, // md (or tight from legacy theme-ui)
  base: 1.35, // sm & lg
  tall: 1.46, // xl (or loose from legacy theme-ui)
  // ---------------------
  // the below might be for centering text inside containers/buttons etc.
  // taller: "2",
  // "3": ".75rem",
  // "4": "1rem",
  // "5": "1.25rem",
  // "6": "1.5rem",
  // "7": "1.75rem",
  // "8": "2rem",
  // "9": "2.25rem",
  // "10": "2.5rem",
}

const letterSpacings = {
  // tighter: '-0.05em',
  // tight: '-0.025em',
  tight: '-0.015em', // -1.5% in figma === -0.015em in code
  normal: '0',
  // wide: '0.025em',
  // wider: '0.05em',
  // widest: '0.1em',
}

const textStyles = {
  default: {
    color: 'text',
    fontFamily: 'body',
    fontSize: 'sm',
    lineHeight: 'short',
    fontWeight: 'normal',
    letterSpacing: 'tight'
  },
  rism: {
    fontFamily: 'body',
    fontSize: 'sm',
    lineHeight: 'base',
    fontWeight: 'normal',
    letterSpacing: 'tight'
  },
  rimd: {
    fontFamily: 'body',
    fontSize: 'md',
    lineHeight: 'short',
    fontWeight: 'normal',
    letterSpacing: 'tight'
  },
  rilg: {
    fontFamily: 'body',
    fontSize: 'lg',
    lineHeight: 'base',
    fontWeight: 'normal',
    letterSpacing: 'tight'
  },
  rixl: {
    fontFamily: 'body',
    fontSize: 'xl',
    lineHeight: 'tall',
    fontWeight: 'normal',
    letterSpacing: 'tight'
  },
  ri2xl: {
    fontFamily: 'body',
    fontSize: '2xl',
    lineHeight: 'shorter',
    fontWeight: 'normal',
    letterSpacing: 'tight'
  }
}

const borderRadius = {
  radii: {
    none: '0',
    base: '5px',
    lg: '10px',
    full: '9999px'
  },
}

// @TODO: refactor for chakra-ui
const forms = {
  input: {
    fontFamily: 'body',
    verticalAlign: 'middle',
    alignItems: 'center',
    borderRadius: 0,
    bg: 'transparent',
    // fontSize: '.875rem',
    // height: '2.3125rem',
    // lineHeight: '1.6875rem',
    width: 'auto',
    outline: 0,
    // mt: 1,
    border: 'none',
    borderBottom: '1px solid',
    borderColor: 'black',
    WebkitAppearance: 'none',
    transition: 'border .2s ease, color .2s ease',
    ':focus': {
      // we could show the arrow on focus
      borderColor: 'gray.5',
    },
    ':disabled': {
      bg: 'gray.1',
      cursor: 'not-allowed',
    },
    ':-webkit-autofill': {
      boxShadow: 'inset 0 0 0 100px #fff',
    },
    '::-webkit-input-placeholder': {
      color: 'gray.4',
    },
    '::-moz-placeholder': {
      color: 'gray.4',
    },
    ':-ms-input-placeholder': {
      color: 'gray.4',
    },
    '&[type="search"]::-webkit-search-decoration': {
      display: 'none',
    },
  },
  disabled: {
    variant: 'forms.input',
    bg: 'gray.1',
    cursor: 'not-allowed',
  },
  textarea: {
    variant: 'forms.input',
    height: 'auto',
    minHeight: '4.625rem',
    lineHeight: '1.5rem',
  },
  select: {
    variant: 'forms.input',
    'input:checked ~ &': {
      color: 'text',
    },
  },
  radio: {
    color: 'gray.4',
    'input:checked ~ &': {
      color: 'text',
    },
  },
  checkbox: {
    color: 'gray.4',
  },
  small: {
    variant: 'forms.input',
    fontSize: '0.75rem',
    height: '2rem',
    lineHeight: '1.375rem',
  },
  large: {
    variant: 'forms.input',
    fontSize: '1rem',
    height: '2.625rem',
    lineHeight: '2.375rem',
  },
  error: {
    variant: 'forms.input',
    borderColor: 'error',
    color: 'error',
  },
  success: {
    variant: 'forms.input',
    borderColor: 'success',
    color: 'success',
  },
  label: {
    color: 'text',
    textAlign: 'left',
    fontSize: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  labelHoriz: {
    color: 'text',
    textAlign: 'left',
    fontSize: 1,
    display: 'flex',
  },
  slider: {
    bg: 'gray.2',
    color: 'success',
  },
  hidden: {
    position: 'absolute',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
}

// Components
// @TODO: Look into adding sizes here (to use instead of textStyle)?
const Text = {
  baseStyle: {
    color: 'text',
    fontFamily: 'body',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      lineHeight: 'base',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    md: {
      fontSize: 'md',
      lineHeight: 'short',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    lg: {
      fontSize: 'lg',
      lineHeight: 'base',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    xl: {
      fontSize: 'xl',
      lineHeight: 'tall',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    '2xl': {
      fontSize: '2xl',
      lineHeight: 'shorter',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    }
  },
  variants: {
    thin: {
      fontFeatureSettings: `'ss02' on, 'case' on`
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: 'normal',
      fontFeatureSettings: `'case' on`
    },
    case: {
      fontFeatureSettings: `'case' on`
    },
    number: {
      fontFeatureSettings: `'tnum' on, 'lnum' on, 'ss02' on, 'ss03' on, 'ss04' on, 'ss20' on, 'case' on`
    }
  },
  defaultProps: {
    // @TODO: set size everywhere that textStyle is used
    // size: 'sm'
  }
}


const inputSize = {
  xl: {
    fontSize: 'xl',
    lineHeight: 'taller',
    p: '1rem',
    h: '8rem',
    borderRadius: 'md',
  }
}

const Input = {
  baseStyle: {
    addon: {
      justify: 'flex-start'
    }
  },
  sizes: {
    xl: {
      field: inputSize.xl,
      addon: inputSize.xl
    }
  },
  variants: {
    flushed: {
      field: {
        borderColor: 'black'
      }
    }
  }
}

// https://github.com/chakra-ui/chakra-ui/blob/4b76f24afbef9f191988cf6a046e7374639fb89a/packages/theme/src/components/heading.ts
// for our headings we need to spec sizes I guess
const Heading = {
  baseStyle: {
    color: 'text',
    fontFamily: 'body',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      lineHeight: 'base',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    md: {
      fontSize: 'md',
      lineHeight: 'short',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    lg: {
      fontSize: 'lg',
      lineHeight: 'base',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    xl: {
      fontSize: 'xl',
      lineHeight: 'tall',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    },
    '2xl': {
      fontSize: '2xl',
      lineHeight: 'shorter',
      fontWeight: 'normal',
      letterSpacing: 'tight'
    }
  },
  variants: {
    thin: {
      fontFeatureSettings: `'ss02' on, 'case' on`
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: 'normal',
    }
  },
  defaultProps: {
    size: 'lg'
  }
}

const Button = {
  // The styles all button have in common
  baseStyle: {
    fontFamily: 'body',
    color: 'text',
    bg: 'transparent',
    // cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    borderRadius: 'none',
    fontFeatureSettings: `'case' on`,
    ...textStyles.rimd,
    ...Text.variants.caps
  },
  sizes: {
    xl: {
      height: '6rem',
      lineHeight: '3rem',
      // minWidth: '12.5rem',
      p: '0 3rem',
    }
  },
  // @TODO: Handle focus-visible pseudo classes here for different use-cases
  // e.g. SelectedFilter buttons which contain either thumbnails or text & thumbnails
  // Two variants: outline and solid
  variants: {
    text: {
      transition: 'text-decoration-color 150ms, text-underline-offset 150ms',
      textDecorationLine: 'underline',
      textUnderlineOffset: '0.2em',
      textDecorationColor: 'transparent',
      textDecorationThickness: '0.075em',
      _hover: {
        textUnderlineOffset: '0.1em',
        textDecorationColor: 'black',
      }
    },
    // outline & solid are nice names for my buttons instead of 'primary' and 'secondary'
    // default naming: solid, ghost, outline, or link.
    outline: {
      border: '1px solid',
      borderColor: 'black',
      color: 'black'
    },
    solid: {
      border: '1px solid',
      bg: 'black',
      _hover: {
        color: 'black',
        bg: 'white',
        _disabled: {
          bg: 'grey.1',
        },
      },
      _active: { bg: 'black' },
    },
    link: {
      color: 'black',
    },
    primary: { // solid
      fontFeatureSettings: `'case' on`,
      border: '1px solid',
      borderColor: 'black',
      borderRadius: 0,
      color: 'white',
      bg: 'black',
      justifyContent: 'center',
      overflow: 'hidden',
      height: '6rem',
      lineHeight: '3rem',
      minWidth: '12.5rem',
      padding: '0 3rem',
      position: 'relative',
      textAlign: 'center',
      transition: '.2s ease',
      transitionProperty: 'color, background-color, border-color, box-shadow',
      userSelect: 'none',
      'span': {
        color: 'white',
        textTransform: 'lowercase'
      },
      width: 'auto',
      ':hover,:focus': {
        color: 'text',
        bg: 'white',
        borderColor: 'black',
        'span': {
          color: 'text',
        }
      },
    },
    secondary: { // outline
      variant: 'buttons.primary',
      bg: 'white',
      color: 'text',
      borderColor: 'black',
      'span': {
        color: 'text',
        textTransform: 'lowercase'
      },
      ':hover,:focus': {
        bg: 'black',
        color: 'white',
        'span': {
          color: 'white',
        }
      },
    },
    disabled: {
      '&,&:hover,&:focus': {
        variant: 'buttons.primary',
        bg: 'gray.1',
        borderColor: 'gray.2',
        color: '#ccc !important',
        cursor: 'not-allowed !important',
      },
    }
  },
  // The default size and variant values
  defaultProps: {
  },
}

const theme = {
  styles,
  colors,
  breakpoints,
  ...spacing,
  zIndices,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  textStyles,
  borderRadius,
  components: {
    Input,
    Text,
    Heading,
    Button
  }
}

export default extendTheme(theme)
